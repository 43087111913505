const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://hoabinh-api.cgis.asia/",
        domainUserSide: "http://localhost:3000",
        domainAdminSide: "http://localhost:3001",
        domainName: "",
        doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
        doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
        datastore: "hoabinh",
  },
  production: {
      api: "https://qhhohoabinh-api.hoabinh.gov.vn/",
      domainUserSide: "https://qhhohoabinh.hoabinh.gov.vn",
      domainAdminSide: "https://qhhohoabinh-admin.hoabinh.gov.vn",
      domainName: "hoabinh.gov.vn",
      doMainNameGeoServer: "https://geo.hoabinh.gov.vn/geoserver/wfs",
      doMainNameGeoServerWMS: "https://geo.hoabinh.gov.vn/geoserver/wms",
      datastore: "hohoabinh",
  },
};

module.exports = apiEnvironment[env];
